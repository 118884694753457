import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";
import { completeBooking, getBooking } from "~/api";

import { Heading, Layout, PrimaryButton } from "~/components";

const CharterBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [booking, setBooking] = useState<any>(null);
  const [passengers, setPassengers] = useState<
    {
      name: string;
      age: number;
      weight: number;
      gender: string;
      idCardNumber: string;
    }[]
  >([]);
  const [success, setSuccess] = useState(false);
  const [token] = useQueryParam<string>("t");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  const fetchBooking = async () => {
    if (!token) return console.log("No token found");

    setIsLoading(true);
    try {
      const bookingResponse = await getBooking(token);
      console.log(bookingResponse);
      setBooking(bookingResponse);
      const seats = bookingResponse.seats;
      const passengerArray = Array.from({ length: seats }, () => ({
        name: "",
        age: "",
        weight: "",
        idCardNumber: "",
        gender: "",
      }));

      setPassengers(passengerArray as any);
    } catch (error) {
      setError("Something went wrong. Please contact customer support.");
    } finally {
      setIsLoading(false);
    }
  };

  const onSavePassengers = async () => {
    if (!token) {
      return alert("Invalid token");
    }
    // Validate passengers
    const invalidPassengers = passengers.filter(
      (passenger) =>
        !passenger.name ||
        !passenger.age ||
        !passenger.weight ||
        !passenger.idCardNumber ||
        !passenger.gender
    );

    if (invalidPassengers.length) {
      return alert("Please fill all the passenger details");
    }

    // Save passengers
    try {
      await completeBooking(booking._id, token, passengers);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      alert("Something went wrong. Please try again later.");
    }
  };

  useEffect(() => {
    fetchBooking();
  }, [token]);

  if (isLoading)
    return (
      <Layout>
        <Heading className="text-center mt-4" type="h4">
          Loading...
        </Heading>
      </Layout>
    );

  if (error)
    return (
      <Layout>
        <Heading className="text-center mt-4" type="h4">
          {error}
        </Heading>
      </Layout>
    );

  if (success)
    return (
      <Layout>
        <Heading className="text-center mt-4" type="h4">
          Booking completed successfully for {booking._id}
        </Heading>
      </Layout>
    );

  return (
    <Layout>
      <Heading className="text-center" type="h2">
        Charter Booking
      </Heading>
      <div>
        {/* Passenger form */}
        <div>
          {passengers.map((passenger, index) => (
            <div key={index}>
              <h3 className="text-left">Passenger {index + 1}</h3>
              <div className="flex flex-col clg:flex-row">
                <input
                  className="p-[6px] border border-gray-300 rounded-lg clg:mr-2 clg:w-[25%] clg:max-w-[300px] w-[100%] mb-2"
                  type="text"
                  placeholder={`Passenger ${index + 1} Name`}
                  value={passenger.name}
                  onChange={(e) => {
                    const newPassengers = [...passengers];
                    newPassengers[index].name = e.target.value;
                    setPassengers(newPassengers);
                  }}
                />
                <input
                  className="p-[6px] border border-gray-300 rounded-lg clg:mr-2 clg:w-[25%] clg:max-w-[300px] w-[100%] mb-2"
                  type="number"
                  placeholder={`Passenger ${index + 1} Age`}
                  value={passenger.age}
                  onChange={(e) => {
                    const newPassengers = [...passengers];
                    newPassengers[index].age = +e.target.value;
                    setPassengers(newPassengers);
                  }}
                />

                <select
                  className="p-[6px] border border-gray-300 rounded-lg clg:mr-2 clg:w-[25%] clg:max-w-[300px] w-[100%] mb-2"
                  onChange={(e) => {
                    const newPassengers = [...passengers];
                    newPassengers[index].gender = e.target.value;
                    setPassengers(newPassengers);
                  }}
                  value={passenger.gender}
                >
                  <option value="" disabled>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>

                <input
                  className="p-[6px] border border-gray-300 rounded-lg clg:mr-2 clg:w-[25%] clg:max-w-[300px] w-[100%] mb-2"
                  type="number"
                  placeholder={`Passenger ${index + 1} weight`}
                  value={passenger.weight}
                  onChange={(e) => {
                    const newPassengers = [...passengers];
                    newPassengers[index].weight = +e.target.value;
                    setPassengers(newPassengers);
                  }}
                />
                <input
                  className="p-[6px] border border-gray-300 rounded-lg clg:mr-2 clg:w-[25%] clg:max-w-[300px] w-[100%] mb-2"
                  type="text"
                  placeholder={`Passenger ${index + 1} Aadhar card number`}
                  value={passenger.idCardNumber}
                  onChange={(e) => {
                    const newPassengers = [...passengers];
                    newPassengers[index].idCardNumber = e.target.value;
                    setPassengers(newPassengers);
                  }}
                />
              </div>
            </div>
          ))}

          <PrimaryButton className="mt-4" onClick={onSavePassengers}>
            Save Passengers
          </PrimaryButton>
        </div>
      </div>
    </Layout>
  );
};

export default CharterBooking;
